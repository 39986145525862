/* eslint-disable */
import useShopStore from '@/stores/shop';
import { isInIframe } from '@/utils/checkInIframe';
import { getTargetGP } from '@/utils/getOriginGP';

const DEFAULT_SETTINGS = {
  app_id: 'z11bwnw3',
  custom_launcher_selector: '#gp-intercom',
  alignment: 'right',
  horizontal_padding: 20,
  vertical_padding: 10,
  action_color: '#333',
  utm_term: 'gempages_app',
  api_base: 'https://z11bwnw3.intercom-messenger.com',
};

export const useIntercom = () => {
  const shopStore = useShopStore();

  const timeZoneUser = Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone;

  const intercomSettings = computed(() => {
    return {
      ...DEFAULT_SETTINGS,
      shopName: shopStore.shop?.shopName,
      user_hash: shopStore.userHash,
      // email: shopStore.shopDomain,
      user_id: shopStore.shopDomain,
      Plan: shopStore.plan,
      // Shopify: shopStore.shopDomain,
      // ShopifyPlan: 'n/a',
      // Purchase: 'n/a',
      created_at: shopStore.shop?.createdAt,
      // ['Payment Type']: 'n/a',
      ['Time zone']: timeZoneUser,
    };
  });

  const initIntercom = () => {
    const w = window as any;
    w.intercomSettings = intercomSettings.value;
    const ic = w.Intercom;
    const urlString = window.location.href;
    const url = new URL(urlString);
    const chatParams = url.searchParams.get('chat');
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', intercomSettings.value);
      if (chatParams && chatParams == 'intercom') {
        w.Intercom('show');
      }
      w.___intercomStatus = 'success';
    } else {
      const d = document;
      const i = function () {
        i.c(arguments);
      };
      i.q = [] as any[];
      i.c = function (args: any) {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = function () {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.setAttribute('data-cookieyes', 'cookieyes-necessary');
        s.async = true;
        w.___intercomStatus = 'running';
        s.onerror = function () {
          console.log('INTERCOM ERROR');
          w.dispatchEvent(new Event('INTERCOM_ERROR'));
          w.___intercomStatus = 'error';
        };
        s.onload = function () {
          console.log('INTERCOM SUCCESS');
          w.___intercomStatus = 'success';
        };
        s.src = 'https://widget.intercom.io/widget/z11bwnw3';
        const x = d.getElementsByTagName('script')[0];
        x.parentNode?.insertBefore(s, x);
        if (chatParams && chatParams == 'intercom') {
          w.Intercom('show');
        }
      };
      l();
    }
  };

  const unWatch = watch(
    () => shopStore.shop?.id,
    (newVal) => {
      if (newVal) {
        initIntercom();
        unWatch();
      }
    },
  );
};

export const useIntercomAction = () => {
  const w = window as any;

  const showInGP = () => {
    if (isInIframe()) {
      window.parent.postMessage('showIntercom', getTargetGP());
      return;
    }
    show();
  };

  const show = () => {
    if (w.Intercom) {
      w.Intercom('show');
    }
  };

  const showWithMessage = (message: String) => {
    if (w.Intercom) {
      w.Intercom('showNewMessage', message);
    }
  };

  return {
    show,
    showInGP,
    showWithMessage,
  };
};
