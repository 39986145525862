import useShopStore from '@/stores/shop';
import { computed } from 'vue';
export default function useLocationHandlers() {
  const shopifyDomain = computed(() => useShopStore().getShopDomain?.split('.')?.[0] ?? '');
  const gempagesAppName = import.meta.env.VITE_GEMPAGES_APP_ID;
  const gemxAppName = import.meta.env.VITE_GEMX_APP_ID;

  /**
   * Get Shopify admin URL
   * @param pathName [apps]/[gempagesAppName]/[pathName]
   * @returns 'https://admin.shopify.com/store/[shopifyDomain]/[apps]/[gempagesAppName]/[pathName]'
   */
  const getShopifyAdminUrl = (pathName: string) => `https://admin.shopify.com/store/${shopifyDomain.value}/${pathName}`;

  /**
   * Get GemX admin dashboard URL
   * @param pathName /[pathName]
   * @example pathName: 'campaigns'
   * @returns 'https://admin.shopify.com/store/[shopifyDomain]/apps/gemx-cro/app/[pathName]'
   */
  const getGemXAdminUrl = (pathName: string) =>
    `https://admin.shopify.com/store/${shopifyDomain.value}/apps/${gemxAppName}/app/${pathName}`;

  const getGempagesAdminUrl = (pathName: string) =>
    `https://admin.shopify.com/store/${shopifyDomain.value}/apps/${gempagesAppName}/app/${pathName}`;

  /**
   * Handle replacing the current window location
   * @param pathName [app]/[pathName] Example: 'app/pricing'
   */
  const handleReplaceLocation = (pathName: string) => {
    const adminUrl = getShopifyAdminUrl(`apps/${gempagesAppName}/${pathName}`);
    window.top?.location.replace(adminUrl);
  };

  /**
   * Handle opening the GP page in a new tab
   * @param pathName [app]/[pathName] Example: 'app/pricing'
   */
  const handleOpenPageGPInNewTab = (pathName: string) => {
    const adminUrl = getShopifyAdminUrl(`apps/${gempagesAppName}/${pathName}`);
    window.open(adminUrl, '_blank');
  };

  /**
   * Handle opening the GP page in a new tab
   * @param pathName [pathName]
   * @example pathName: 'campaigns'
   * @Output 'https://admin.shopify.com/store/[shopifyDomain]/apps/gemx-cro/app/[pathName]'
   */
  const handleOpenPageGemXInNewTab = (pathName: string) => {
    const adminUrl = getGemXAdminUrl(pathName);
    window.open(adminUrl, '_blank');
  };

  /**
   * Handle opening Shopify page in a new tab
   * @param pathName [pathName] Example: 'themes' or 'products'
   */
  const handleOpenPageShopifyInNewTab = (pathName: string) => {
    const adminUrl = getShopifyAdminUrl(pathName);
    window.open(adminUrl, '_blank');
  };

  const handleOpenUrlInNewTab = (url?: string) => {
    url && window.open(url, '_blank');
  };

  return {
    getShopifyAdminUrl,
    getGemXAdminUrl,
    getGempagesAdminUrl,
    handleOpenUrlInNewTab,
    handleReplaceLocation,
    handleOpenPageGPInNewTab,
    handleOpenPageShopifyInNewTab,
    handleOpenPageGemXInNewTab,
  };
}
